




























































import Vue from 'vue';
export default Vue.extend({
  props: {
    question: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
    currentSession: {
      type: Object,
      required: true,
    },
  },
});
