































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { formatDateTime, millisToTimeString } from '@/utils/tools';
import BoardColumn from '@/components/Viewer/BoardColumn.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { BoardColumn },
  data() {
    return {
      now: new Date(),
      pollTypes: ['Singlechoice', 'Multiplechoice '],
    };
  },
  async mounted() {
    await this.$store.dispatch(
      'presenter/fetchTVSession',
      this.$route.params.sessionId
    );
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionTheme: 'viewer/getSessionTheme',
      columns: 'questions/getBoardColumns',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    computedColumns(): any[] {
      return this.columns;
    },
    elapsedTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const elapsedTime =
            this.now.getTime() - this.currentSession.startAt.toDate().getTime();
          return millisToTimeString(elapsedTime);
        } else if (this.now > this.currentSession.endAt.toDate()) {
          return this.$t('views.presenter.sessionEnded') as string;
        }
        return (
          (this.$t('views.presenter.sessionStartsAt') as string) +
          `${formatDateTime(this.currentSession.startAt.toDate())}`
        );
      }
      return '-';
    },
    remainingTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const remainingTime =
            this.currentSession.endAt.toDate().getTime() - this.now.getTime();
          return millisToTimeString(remainingTime);
        }
      }
      return '';
    },
  },
});
