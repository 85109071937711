





































import Vue from 'vue';
import QuestionCard from './QuestionCard.vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: {
    QuestionCard,
  },

  props: {
    column: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getQuestions: 'questions/getColumnQuestions',
      answers: 'questions/getAnswers',
      currentSession: 'presenter/getCurrentSession',
    }),
    questions() {
      return this.getQuestions(this.column.id);
    },
  },
});
