var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-card px-7 py-4 text-left bg-white shadow-question-card"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('p',{staticClass:"text-sm",style:({
        color: _vm.isGreenscreenEnabled ? '#fff' : _vm.theme.textColor,
      })},[_vm._v(" # "+_vm._s(_vm.question.initialPosition)+" ")]),(
        _vm.currentSession.showQuestionsUserNameInput && _vm.question.questionerName
      )?_c('p',{class:("text-sm " + (_vm.isHighlighted ? 'text-white' : 'text-light-gray'))},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.tvFromFormatted', { name: _vm.question.questionerName, }))+" ")]):_vm._e()]),_c('h5',{staticClass:"text-md",style:({
      color: _vm.isGreenscreenEnabled ? '#fff' : _vm.theme.headlineColor,
    })},[_vm._v(" "+_vm._s(_vm.question.title)+" ")]),_c('div',{staticClass:"flex flex-row justify-end items-center mt-3"},[_c('svg',{staticClass:"w-5 h-5",attrs:{"viewBox":"0 0 12 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.24999 5.79169C0.560998 5.79169 0 6.35268 0 7.04167V11.0417C0 11.7307 0.560998 12.2917 1.24999 12.2917H2.74998C3.03149 12.2917 3.29047 12.1967 3.49998 12.0386V5.79169H1.24999Z","fill":_vm.isGreenscreenEnabled ? '#fff' : _vm.theme.buttonColor}}),_c('path',{attrs:{"d":"M12 7.66671C12 7.36622 11.881 7.08621 11.6745 6.87971C11.908 6.62421 12.027 6.2817 11.9945 5.92522C11.936 5.28971 11.3634 4.79171 10.6905 4.79171H7.60198C7.75498 4.32721 7.99997 3.47573 7.99997 2.79173C7.99997 1.70724 7.07848 0.791748 6.49997 0.791748C5.98048 0.791748 5.60947 1.08425 5.59349 1.09625C5.53449 1.14375 5.49999 1.21575 5.49999 1.29174V2.98722L4.06 6.10672L4 6.13721V11.4962C4.40699 11.6882 4.92198 11.7917 5.24999 11.7917H9.83948C10.384 11.7917 10.8605 11.4247 10.9725 10.9182C11.03 10.6577 10.9965 10.3952 10.882 10.1672C11.2515 9.9812 11.5 9.60069 11.5 9.1667C11.5 8.98971 11.4595 8.82021 11.3825 8.66671C11.752 8.48071 12 8.10021 12 7.66671Z","fill":_vm.isGreenscreenEnabled ? '#fff' : _vm.theme.buttonColor}})]),_c('p',{staticClass:"pl-2 text-sm",style:({
        color: _vm.isGreenscreenEnabled ? '#fff' : _vm.theme.primaryColor,
      })},[_vm._v(" "+_vm._s(_vm.question.upvotes)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }